import React, { useEffect, useState } from "react";
import { Form, Image, InputGroup } from "react-bootstrap";
import Button from "../../../../Components/Button";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../../Redux/reducer";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomerList, postAdminTicket } from "../../../../Service";
import { FaFilePdf } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";

const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

const TicketAddForm = () => {
  // const { id } = useParams();
  const goto = useNavigate();
  const dispatch = useDispatch();
  // const [ticketId, setTicketId] = useState("");
  const [previews, setPreviews] = useState([]);
  const [customerEmail, setCustomerEmail] = useState([]);

  useEffect(() => {
    dispatch(setActivePage("ticket"));
  }, [dispatch]);

  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const data = await getCustomerList();
        setCustomerEmail(data.customer_data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCustomerList();
  }, []);

  // useEffect(() => {
  //   const fetchCustomerList = async () => {
  //     try {
  //       const data = await getACustomerTicket(id);
  //       formik.setValues({
  //         subject: data.ticket.subject,
  //         description: data.ticket.description,
  //         attachments: [],
  //       });
  //       setTicketId(data.ticket.ticket_id);

  //       const existingPreviews = data.ticket.attachment.map((filePath) => ({
  //         type: filePath.endsWith(".pdf") ? "pdf" : "image",
  //         url: filePath,
  //         name: filePath.split("/").pop(),
  //       }));
  //       setPreviews(existingPreviews);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   if (id !== "add") {
  //     fetchCustomerList();
  //   }
  // }, [id]);

  const validationSchema = Yup.object({
    subject: Yup.string().required("Subject is required"),
    description: Yup.string().required("Description is required"),
    customer_email: Yup.string().required("Customer email is required"),
    status: Yup.string(),
    priority: Yup.string(),
    attachments: Yup.array().of(
      Yup.mixed().test(
        "fileSize",
        `File must be less than ${MAX_FILE_SIZE_MB} MB`,
        (file) => (file ? file.size <= MAX_FILE_SIZE : true)
      )
    ),
  });

  const formik = useFormik({
    initialValues: {
      subject: "",
      description: "",
      customer_email: "",
      status: "",
      priority: "",
      attachments: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const fetchFileFromUrl = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], url.split("/").pop(), { type: blob.type });
      };

      const payload = {
        subject: values.subject,
        description: values.description,
        customer_email: values.customer_email,
        status: values.status,
        priority: values.priority,
        attachments: [],
      };

      // Handle new file attachments
      for (const attachment of values.attachments) {
        if (attachment instanceof File) {
          payload.attachments.push(attachment);
        }
      }

      // Handle files from existing previews
      for (const preview of previews) {
        if (preview.url && !preview.url.startsWith("blob:")) {
          const file = await fetchFileFromUrl(preview.url);
          payload.attachments.push(file);
        }
      }

      try {
        // if (id === "add") {
        await postAdminTicket(payload);
        // } else {
        //   await editCustomerTicket(payload, id);
        // }
        formik.resetForm();
        setPreviews([]);
        goto("/admin/tickets");
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Exclude .exe and .bat files
    const invalidFiles = files.filter(
      (file) => file.name.endsWith(".exe") || file.name.endsWith(".bat")
    );

    if (invalidFiles.length > 0) {
      alert(
        `The following files are not allowed: ${invalidFiles
          .map((file) => file.name)
          .join(", ")}`
      );
    }

    const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);
    if (files.length !== validFiles.length) {
      alert(
        `Some files exceed the ${MAX_FILE_SIZE_MB} MB limit and were not added.`
      );
    }

    const newPreviews = validFiles.map((file) => {
      let type;

      // Determine file type for icon
      if (file.name.endsWith(".pdf")) {
        type = "pdf";
      } else if (file.name.endsWith(".doc") || file.name.endsWith(".docx")) {
        type = "word";
      } else if (file.name.endsWith(".xls") || file.name.endsWith(".xlsx")) {
        type = "excel";
      } else if (file.name.endsWith(".csv")) {
        type = "csv";
      } else if (file.type.startsWith("image/")) {
        type = "image";
      } else {
        type = "file";
      }

      return {
        type,
        url: type === "image" ? URL.createObjectURL(file) : undefined,
        name: file.name,
      };
    });

    setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
    formik.setFieldValue("attachments", [
      ...formik.values.attachments,
      ...validFiles,
    ]);
  };

  const handleRemoveFile = (indexToRemove) => {
    const newAttachments = formik.values.attachments.filter(
      (_, index) => index !== indexToRemove
    );
    setPreviews((prev) => prev.filter((_, index) => index !== indexToRemove));
    formik.setFieldValue("attachments", newAttachments);
  };

  return (
    <div
      className="profile-section"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div
        className="bg-white bg-space-radius"
        style={{ width: "50%", margin: "0 auto", height: "auto" }}
      >
        <h3 className="sub-title mb-4">Create Ticket</h3>
        <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Form.Group className="mb-3">
            <Form.Label>Reported By</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                name="customer_email"
                value={formik.values.customer_email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.customer_email &&
                  formik.touched.customer_email
                }
              >
                <option value="">Select</option>
                {customerEmail.map((data) => {
                  return <option value={data.email}>{data.email}</option>;
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.customer_email}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formSubject" className="mb-4">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              name="subject"
              placeholder="Enter subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              isInvalid={formik.touched.subject && !!formik.errors.subject}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.subject}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formDescription" className="mb-4">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              rows={4}
              placeholder="Enter description"
              value={formik.values.description}
              onChange={formik.handleChange}
              isInvalid={
                formik.touched.description && !!formik.errors.description
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Priority</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                name="priority"
                value={formik.values.priority}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.priority && formik.touched.priority}
              >
                <option value="">Select</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.priority}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.status && formik.touched.status}
                disabled={formik.values.status === "Cancelled"}
              >
                <option value="New">New</option>
                <option value="Pending">Pending</option>
                <option value="On hold">On Hold</option>
                <option value="Resolved">Resolved</option>
                <option value="Closed">Closed</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.status}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-4">
            <Form.Label>Attachments</Form.Label>
            <Form.Control
              type="file"
              name="attachments"
              multiple
              // accept="image/jpeg, image/png, image/jpg, application/pdf"
              onChange={handleFileChange}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.attachments}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="mt-3">
            {previews.map((preview, index) => (
              <div
                key={index}
                className="d-inline-block me-2 mb-2 position-relative"
              >
                {preview.type === "image" ? (
                  <Image
                    src={preview.url}
                    thumbnail
                    alt="Selected Image"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div className="d-inline-flex flex-column align-items-center">
                    {preview.type === "pdf" && (
                      <FaFilePdf size={50} color="#f00" />
                    )}
                    {preview.type === "word" && (
                      <i
                        className="bi bi-file-earmark-word"
                        style={{ fontSize: "50px", color: "#2b579a" }}
                      ></i>
                    )}
                    {preview.type === "excel" && (
                      <i
                        className="bi bi-file-earmark-excel"
                        style={{ fontSize: "50px", color: "#217346" }}
                      ></i>
                    )}
                    {preview.type === "csv" && (
                      <i
                        className="bi bi-file-earmark-spreadsheet"
                        style={{ fontSize: "50px", color: "#217346" }}
                      ></i>
                    )}
                    {preview.type === "file" && (
                      <i
                        className="bi bi-file-earmark"
                        style={{ fontSize: "50px", color: "#6c757d" }}
                      ></i>
                    )}
                    <p className="file-name text-center mb-1">{preview.name}</p>
                  </div>
                )}
                <Button
                  buttonMode="button"
                  type="danger"
                  size="extra-small"
                  label="&times;"
                  className="position-absolute top-0 end-0"
                  style={{ transform: "translate(50%, -50%)" }}
                  onClick={() => handleRemoveFile(index)}
                />
              </div>
            ))}
          </div>

          <div
            className="d-flex justify-content-end mt-4"
            style={{ gap: "15px" }}
          >
            <Button
              onClick={() => goto(-1)}
              label="Cancel"
              type="secondary"
              buttonMode="button"
            />
            <Button label="Add" type="primary" buttonMode="submit" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default TicketAddForm;
