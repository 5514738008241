import React from "react";
import GaugeChart from "react-gauge-chart";
import { useSelector } from "react-redux";

const WanStatusGauge = ({ value }) => {
  const theme = useSelector((state) => state.theme.mode);
  return (
    <div style={{ width: 300 }} className="m-auto battery-container">
      <GaugeChart
        id="wan-status-gauge"
        nrOfLevels={30} // Number of levels (ticks on the meter)
        arcsLength={[0.3, 0.5, 0.2]} // Proportion of each section (red, yellow, green)
        colors={["#24D4B0", "#14CEE9", "#1C66B4"]} // Colors for each section
        percent={value / 100} // Converts value from percentage to 0-1 scale
        arcPadding={0.02} // Padding between each arc
        needleColor="#04CAE780" // Needle color
        needleBaseColor="#04CAE7" // Needle base color
        textColor={theme === "dark" ? "#fff" : "#000"} // Text color based on theme
        animate={true} // Animate the needle movement
        animDelay={0} // Animation delay
        arcWidth={0.1}
      />
    </div>
  );
};

export default WanStatusGauge;
