import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import exportIcon from "../../../Assets/Images/export-icon.svg";
import filterIcon from "../../../Assets/Images/file.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import {
  Box,
  IconButton,
  Tooltip,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Button from "../../../Components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import {
  deleteCustomerNodes,
  exportNodes,
  getCustomerNodes,
} from "../../../Service";
import NodesPopup from "./NodesPopup";
import AlertBox from "../../../Components/AlertBox";
import { formatDate } from "../../../Utils";
import ProgressBar from "../../../Components/ProgressBar";
import FilterPopup from "./FilterPopup";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#161C29",
      paper: "#1c1c1e !important",
    },
    text: {
      primary: "#e0e0e0",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1c1c1e !important",
          "& .MuiBox-root": {
            backgroundColor: "#1c1c1e !important",
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
        },
      },
    },
    MuiTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important", // Dark background for header
          color: "#e0e0e0", // Text color in dark mode
          borderBottom: "1px solid rgba(81, 81, 81, 0.5)",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& td": {
              position: "relative",
              "&:after": {
                content: '""',
                backgroundColor: "#242425 !important",
              },
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
          borderBottom: "1px solid #373737",
          color: "#e0e0e0", // Ensure text color is visible
          "& .MuiBox-root": {
            backgroundColor: "#242425 !important",
          },
        },
      },
    },
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#ffffff", // Light background for the app
      paper: "#f5f5f5", // Light background for paper elements like the table
    },
    text: {
      primary: "#000000", // Dark text color for light mode
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5", // Light background for table container
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent", // Table cell background remains transparent
        },
      },
    },
  },
});

const Nodes = () => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [mode, setMode] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [tableData, setTableData] = useState("");
  const [deleteData, setDeleteData] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [filterShow, setFilterShow] = useState(false);
  const [filters, setFilters] = useState({});

  const theme = useSelector((state) => state.theme.mode);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const data = await getCustomerNodes(
          filters.name,
          filters.type,
          filters.startDate,
          filters.endDate
        );
        setData(data.objects);
      } catch (error) {
        console.log(error);
      }
    };
    dispatch(setActivePage("nodes"));
    fetchCustomerList();
  }, [dispatch, isAdded, filters]);

  const handleApplyFilters = (appliedFilters) => {
    setFilters(appliedFilters);
  };

  function added() {
    setIsAdded(!isAdded);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "type",
        header: "Type",
      },

      {
        accessorKey: "value",
        header: "Value",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell, row }) => {
          return (
            <ProgressBar
              successPercentage={row.original.true_percentage}
              failurePercentage={row.original.false_percentage}
              successCount={row.original.true_count}
              failureCount={row.original.false_count}
              totalCount={row.original.total_tasks_count}
            />
          );
        },
      },
      {
        accessorKey: "created_at",
        header: "Created On",
        accessorFn: (row) => new Date(row.created_at), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box
        sx={{ display: "flex", gap: "1rem", minWidth: "100px", width: "100px" }}
      >
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setMode("edit");
              setTableData(row.original);
              setModalShow(true);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  await deleteCustomerNodes({
                    object_ids: [row.original.id],
                  });
                  setAlert(false);
                  added();
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{ gap: "15px" }}>
        <Button
          onClick={() => {
            setMode("add");
            setModalShow(true);
          }}
          icon={addIcon}
          label="Add"
          type="primary"
        />

        <Button
          icon={filterIcon}
          label="Filter"
          type="primary"
          onClick={() => {
            setFilterShow(true);
          }}
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.id;
              });
              setDeleteData({
                onClick: async () => {
                  try {
                    await deleteCustomerNodes({ object_ids: newArray });
                    setAlert(false);
                    setRowSelection({});
                    added();
                  } catch {}
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}

        <Button
          onClick={async () => {
            try {
              const response = await exportNodes(
                filters.name,
                filters.type,
                filters.startDate,
                filters.endDate
              );
              const downloadUrl = response.download_url;

              if (downloadUrl) {
                // Create an anchor element and set the download URL
                const link = document.createElement("a");
                link.href = downloadUrl;

                // Set the download attribute with a filename, ensuring it ends with .csv
                link.download = "nodes_export.csv";

                document.body.appendChild(link);
                link.click();

                // Remove the link from the document after the download is initiated
                document.body.removeChild(link);
              }
            } catch (error) {
              console.error("Error exporting nodes:", error);
            }
          }}
          icon={exportIcon}
          label="Export"
          type="secondary"
        />
      </div>
    ),
  });

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h4 className="sub-title">Nodes</h4>
        </div>
        <MaterialReactTable table={table} />
      </div>
      <NodesPopup
        show={modalShow}
        mode={mode}
        tableData={tableData}
        added={added}
        onHide={() => setModalShow(false)}
      />

      <AlertBox
        show={alert}
        onHide={() => setAlert(false)}
        deleteData={deleteData}
      />

      <FilterPopup
        show={filterShow}
        onHide={() => setFilterShow(false)}
        onApplyFilters={handleApplyFilters}
      />
    </ThemeProvider>
  );
};

export default Nodes;
