import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "../../../Components/Button";
import { adminTicketupdate } from "../../../Service";
import { InputGroup } from "react-bootstrap";

const initialForm = {
  status: "",
  priority: "",
  comment: "",
};

const validationSchema = Yup.object({
  status: Yup.string(),
  priority: Yup.string(),
  comment: Yup.string(),
});
const TicketPopup = (props) => {
  const [disabled, setDisabled] = useState(false);

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!disabled) {
        const sanitizedValues = {
          ticket_id: values.ticket_id,
          status: values.status,
          priority: values.priority,
          comment: values.comment,
        };
        setDisabled(true);
        try {
          await adminTicketupdate(sanitizedValues);
          props.added();
          props.onHide();
          resetForm();
        } catch (err) {
          console.log(err);
        } finally {
          setDisabled(false);
        }
      }
    },
  });

  useEffect(() => {
    if (props.show) {
      formik.setValues(props.tableData);
    }
  }, [props.show, props.tableData]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExited={formik.resetForm}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Tickets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={!disabled ? formik.handleSubmit : () => {}}>
          <Form.Group className="mb-3">
            <Form.Label>Priority</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                name="priority"
                value={formik.values.priority}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.priority && formik.touched.priority}
              >
                <option value="">Select</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.priority}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.status && formik.touched.status}
                disabled={formik.values.status === "Cancelled"}
              >
                <option value="New">New</option>
                <option value="Pending">Pending</option>
                <option value="On hold">On Hold</option>
                <option value="Resolved">Resolved</option>
                <option value="Closed">Closed</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.status}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Comments</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                as="textarea"
                rows={3} // Optional: Adjust the number of rows in the textarea
                value={formik.values.comment}
                name="comment"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.comment && formik.touched.comment}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.comment}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label="Update"
              buttonMode="submit"
              disabled={disabled}
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default TicketPopup;
