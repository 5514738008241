import React, { useEffect, useState } from "react";
import "./style.css";
import logo from "../../Assets/Images/d-logo.png";
import logoSmall from "../../Assets/Images/logo-1.png";
import bxMenu from "../../Assets/Images/bx-menu.svg";
import profile from "../../Assets/Images/profile-img.svg";
import device from "../../Assets/Images/my-devices.svg";
import adminService from "../../Assets/Images/admin-services-icon.svg";
import report from "../../Assets/Images/reports.svg";
import ticket from "../../Assets/Images/raise-tickets.svg";
import notification from "../../Assets/Images/notification.svg";
import logout from "../../Assets/Images/logout.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authService from "../../Service/authService";
import { io } from "socket.io-client";
import { setUnreadCount } from "../../Redux/notificationSlice";
import { adminNotificationList } from "../../Service";

const SidemenuAdmin = () => {
  const [tickets, setTickets] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const unreadCount = useSelector((state) => state.notification.unreadCount);

  const dispatch = useDispatch();

  const fetchNotificationList = async () => {
    try {
      const data = await adminNotificationList();
      const unread = data.filter((ticket) => !ticket.is_read).length; // Calculate unread tickets
      dispatch(setUnreadCount(unread)); // Update unread count
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchNotificationList();
  }, []);

  useEffect(() => {
    // Connect to the Socket.IO server
    const socket = io(process.env.REACT_APP_API_SOCKET_URL); // Adjust the URL to match your backend

    // Listen for the 'connect' event
    socket.on("connect", () => {
      console.log("Connected to Socket.IO server");
      console.log("Socket ID:", socket.id);
      setSocketConnected(true);

      // Optionally, join a specific room
      socket.emit("join", { room: "admin_room" });
    });

    // Handle connection errors
    socket.on("connect_error", (error) => {
      console.error("Connection failed:", error);
    });

    // Listen for the 'new_ticket' event
    socket.on("new_ticket", (data) => {
      console.log("New ticket received:", data);
      // alert(
      //   `New ticket created!\nTicket ID: ${data.ticket_id}\nSubject: ${data.subject}`
      // );
      fetchNotificationList();
      setTickets((prevTickets) => [...prevTickets, data]); // Add new ticket to the state
    });

    // Handle disconnections
    socket.on("disconnect", () => {
      console.log("Disconnected from the server");
      setSocketConnected(false);
    });

    // Cleanup the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  const theme = useSelector((state) => state.theme.mode);
  const activePage = useSelector((state) => state.dmon.activePage);
  const goto = useNavigate();
  return (
    <div className="sidebar sidebar-position">
      <div className="logo-details py-3">
        <span className="d-logo">
          <img src={logo} alt="" width={40} height={40} />
        </span>
        <span className="full-logo">
          <div>
            <img src={logoSmall} alt="project Logo" />
          </div>
        </span>
        <span className="bx-menu">
          <img src={bxMenu} alt="" width={12} height={13} />
        </span>
      </div>

      <ul
        className="nav-links sidebar-accordion accordion mt-5"
        id="accordionExample"
      >
        <li
          onClick={() => {
            goto("/admin");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "customer" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={profile} alt="" />
            </div>
            <span className="link_name ">Customer</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Customer</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/devices");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "device" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={device} alt="" />
            </div>
            <span className="link_name ">Devices</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Devices</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/services");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "service" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={adminService} alt="" />
            </div>
            <span className="link_name ">Tasks</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Tasks</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/reports");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "report" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={report} alt="" />
            </div>
            <span className="link_name ">Reports</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Reports</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/tickets");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "ticket" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={ticket} alt="" />
            </div>
            <span className="link_name notification-dot-wrapper">
              <div>Tickets</div>
            </span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div>Tickets</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/notification");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "notification" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={notification} alt="" />
            </div>
            <span className="link_name notification-dot-wrapper">
              <div>Notification</div>
              {unreadCount !== 0 && (
                <div className="notification-dot"> {unreadCount} </div>
              )}
            </span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Notification</div>
            </li>
          </ul>
        </li>
        <li
          onClick={async () => {
            await authService.logout();
            goto("/");
          }}
        >
          <div className="sidemenu-wrapper">
            <div className="icon-box">
              <img src={logout} alt="" />
            </div>
            <span className="link_name">Logout</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Logout</div>
            </li>
          </ul>
        </li>
      </ul>
      <div className="w-100 copyright-position">
        <p className="copy-right">Copyright &copy; 2024. All right reserved.</p>
      </div>
    </div>
  );
};

export default SidemenuAdmin;
