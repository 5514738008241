import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Button from "../../../Components/Button";
import addIcon from "../../../Assets/Images/add-white.svg";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import comment from "../../../Assets/Images/comment.svg";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import filterIcon from "../../../Assets/Images/file.svg";
import { getAdminTickets } from "../../../Service";
import { formatDate } from "../../../Utils";
import {
  Box,
  IconButton,
  Tooltip,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import TicketPopup from "./TicketPopup";
import FilterPopup from "./FilterPopup";
import ReadMore from "../../../Components/ReadMore";
import { useNavigate } from "react-router-dom";
import CommentBox from "./CommentBox";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#161C29",
      paper: "#1c1c1e !important",
    },
    text: {
      primary: "#e0e0e0",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1c1c1e !important",
          "& .MuiBox-root": {
            backgroundColor: "#1c1c1e !important",
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
        },
      },
    },
    MuiTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important", // Dark background for header
          color: "#e0e0e0", // Text color in dark mode
          borderBottom: "1px solid rgba(81, 81, 81, 0.5)",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& td": {
              position: "relative",
              "&:after": {
                content: '""',
                backgroundColor: "#242425 !important",
              },
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
          borderBottom: "1px solid #373737",
          color: "#e0e0e0", // Ensure text color is visible
          "& .MuiBox-root": {
            backgroundColor: "#242425 !important",
          },
        },
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#ffffff", // Light background for the app
      paper: "#f5f5f5", // Light background for paper elements like the table
    },
    text: {
      primary: "#000000", // Dark text color for light mode
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5", // Light background for table container
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent", // Table cell background remains transparent
        },
      },
    },
  },
});

const Tickets = () => {
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [ticketData, setTicketData] = useState();
  const [isAdded, setIsAdded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowComment, setModalShowComment] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [filters, setFilters] = useState({});

  const theme = useSelector((state) => state.theme.mode);

  const dispatch = useDispatch();
  const goto = useNavigate();

  const handleApplyFilters = (appliedFilters) => {
    setFilters(appliedFilters);
  };

  useEffect(() => {
    console.log(filters, "Filter");
  }, [filters]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_by",
        header: "Requested By",
        accessorFn: (row) => `${row.created_by.email}`,
      },
      {
        accessorKey: "ticket_id",
        header: "Ticket Id",
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ cell, row }) => {
          return (
            <>
              <div>{row.original.subject}</div>
              <ReadMore
                text={row.original.description}
                heading={row.original.subject}
              />
            </>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "priority",
        header: "Priority",
      },
      {
        accessorKey: "created_at",
        header: "Created Date",
        accessorFn: (row) => new Date(row.created_at), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const data = await getAdminTickets(
          filters.priority,
          filters.status,
          filters.startDate,
          filters.endDate
        );
        setData(data.tickets);
      } catch (error) {
        console.log(error);
      }
    };

    dispatch(setActivePage("ticket"));
    fetchTickets();
  }, [dispatch, isAdded, filters]);

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Comments">
          <IconButton
            onClick={() => {
              // setMode("edit");
              setTicketData(row.original);
              setModalShowComment(true);
            }}
          >
            <img src={comment} alt="edit" />
          </IconButton>
        </Tooltip>

        {row.original.status !== "Cancelled" && (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                // setMode("edit");
                setTicketData(row.original);
                setModalShow(true);
              }}
            >
              <img src={editIcon} alt="edit" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{ gap: "15px" }}>
        <Button
          icon={filterIcon}
          label="Filter"
          type="primary"
          onClick={() => {
            setFilterShow(true);
          }}
        />
        <Button
          icon={addIcon}
          label="Add"
          type="primary"
          onClick={() => {
            goto("/admin/create-ticket");
            // setFilterShow(true);
          }}
        />
      </div>
    ),
  });

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h4 className="sub-title">Tickets</h4>
        </div>
        <MaterialReactTable table={table} />

        <TicketPopup
          show={modalShow}
          onHide={() => setModalShow(false)}
          added={() => setIsAdded(!isAdded)}
          tableData={ticketData}
        />

        <CommentBox
          show={modalShowComment}
          onHide={() => setModalShowComment(false)}
          tableData={ticketData}
        />

        <FilterPopup
          show={filterShow}
          onHide={() => setFilterShow(false)}
          onApplyFilters={handleApplyFilters}
        />
      </div>
    </ThemeProvider>
  );
};

export default Tickets;
