import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unreadCount: 0, // State to handle 401/403 errors
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload; // Set the auth error state
    },
  },
});

export const { setUnreadCount } = notificationSlice.actions;

export default notificationSlice.reducer;
