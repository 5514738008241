import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleTheme } from "../../Redux/themeSlice";
import DarkImg from "../../Assets/Images/dark.svg";
import LightImg from "../../Assets/Images/light.svg";
import DarkWhiteImg from "../../Assets/Images/white-dark.svg";
import LightWhiteImg from "../../Assets/Images/white-light.svg";

const ThemeToggleButton = () => {
  const theme = useSelector((state) => state.theme.mode);
  const dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <div className="col-lg-12 text-end">
      {/* <button onClick={() => dispatch(toggleTheme())} style={{ background: 'transparent',
    border: '0',
    boxShadow: 'none',
    color: '#fff'}}>
      Switch to {theme === "light" ? "Dark" : "Light"} Mode
    </button> */}
      {/*  */}
      {/* <Form>
      <Form.Check
        type="switch"
        id="custom-switch-1"
        label={theme === "light" ? "Light Mode" : "Dark Mode"} // Optional label
        checked={theme === "dark"} // Reflect the current theme in the switch state
        onChange={() => dispatch(toggleTheme())} // Toggle theme on change
      />
    </Form> */}

      {/*  */}
      <div
        onClick={() => dispatch(toggleTheme())}
        style={{
          background: theme === "light" ? "#EDEDF0" : "#161C29",
          borderRadius: "15px",
          padding: "5px",
          display: "flex",
          alignItems: "center",
          width: "80px",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          marginLeft: "auto",
        }}
      >
        {/* Left Icon (Moon for dark mode, Sun for light mode) */}
        <div className="me-2">
          <img
            src={theme === "light" ? DarkImg : DarkWhiteImg}
            alt={theme === "light" ? "Dark Mode Icon" : "Light Mode Icon"}
            style={{
              width: "30px",
              background: theme === "light" ? "transparent" : "#252D3E",
              padding: "6px",
              borderRadius: "30px",
            }}
          />
        </div>

        {/* Right Icon (Sun for light mode, Moon for dark mode) */}
        <div>
          <img
            src={theme === "light" ? LightImg : LightWhiteImg}
            alt={theme === "light" ? "Light Mode Icon" : "Dark Mode Icon"}
            style={{
              width: "30px",
              background: theme === "light" ? "#fff" : "transparent",
              padding: "6px",
              borderRadius: "30px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ThemeToggleButton;
