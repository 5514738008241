import React, { useState } from "react";
import Button from "../../Components/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { passwordConfirm } from "../../Service";

const initialForm = {
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const NewPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const goto = useNavigate();

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const { password } = values; // Extract only the password
      try {
        // Replace with your API call
        // await authService.passwordReset({ password });
        try {
          await passwordConfirm({ new_password: password });
        } catch (err) {
          console.log(err);
        }
        console.log("Password sent to API:", password);
        resetForm();
        goto("/");
      } catch (err) {
        console.error(err);
      }
    },
  });

  return (
    <div className="inside-right-box form-details">
      <Form noValidate onSubmit={formik.handleSubmit}>
      <div className="mb-5 text-center" style={{color: '#000'}}>
              <h2 style={{color: '#000'}}>Set New Password</h2>
            </div>
        {/* Password Field */}
        <Form.Group className="mb-4 position-relative">
          <InputGroup hasValidation>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Enter the New Password"
              required
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.password && formik.touched.password}
              name="password"
              id="password"
              className="ps-3"
            />
            <div
              className="icon-eye"
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: "pointer" }}
            >
              <i
                className={`fa-regular ${
                  showPassword ? "fa-eye" : "fa-eye-slash"
                }`}
                id="iconToggles"
              ></i>
            </div>
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        {/* Confirm Password Field */}
        <Form.Group className="mb-4 position-relative">
          <InputGroup hasValidation>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              required
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.errors.confirmPassword &&
                formik.touched.confirmPassword
              }
              name="confirmPassword"
              id="confirmPassword"
              className="ps-3"
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.confirmPassword}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        {/* Submit Button */}
        <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
          <Button type="primary" label="Save" buttonMode="submit" />
        </div>
      </Form>
    </div>
  );
};

export default NewPassword;
