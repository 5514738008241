import React, { useEffect, useState } from "react";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import subject from "../../../../Assets/Images/subject.svg";
import status from "../../../../Assets/Images/status.svg";
import description from "../../../../Assets/Images/description.svg";
import attachment from "../../../../Assets/Images/attachment.svg";
import CommentSection from "../../../../Components/CommentSection";
import { FaFilePdf } from "react-icons/fa";
import { ticketCommentsById } from "../../../../Service";

const CommentBox = (props) => {
  const [previews, setPreviews] = useState([]);
  const [comments, setComments] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);

  const fetchComment = async () => {
    try {
      const data = await ticketCommentsById(props.tableData.ticket_id);
      setComments(data.comments);
      setLoaded(true);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  };

  useEffect(() => {
    if (props.show) {
      if (!loaded || postSuccess) {
        const existingPreviews = props.tableData.attachment.map((filePath) => {
          let type;
          if (filePath.endsWith(".pdf")) {
            type = "pdf";
          } else if (filePath.endsWith(".doc") || filePath.endsWith(".docx")) {
            type = "word";
          } else if (filePath.endsWith(".xls") || filePath.endsWith(".xlsx")) {
            type = "excel";
          } else if (filePath.endsWith(".csv")) {
            type = "csv";
          } else if (
            filePath.endsWith(".jpg") ||
            filePath.endsWith(".jpeg") ||
            filePath.endsWith(".png") ||
            filePath.endsWith(".gif") ||
            filePath.endsWith(".bmp")
          ) {
            type = "image";
          } else {
            type = "file";
          }
          return {
            type,
            url: filePath,
            name: filePath.split("/").pop(),
          };
        });

        setPreviews(existingPreviews);
        fetchComment();
        setPostSuccess(false); // Reset postSuccess after fetching
      }
    }
  }, [props.show, loaded, props.tableData, postSuccess]);

  const handlePostSuccess = () => {
    setPostSuccess(true); // Trigger re-fetching of comments
  };

  const handleClose = () => {
    setLoaded(false); // Reset loading state when modal is closed
    props.onHide(); // Call parent's `onHide` handler if provided
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="comment-wrapper">
          <div className="comment-header-wrapper">
            <div className="icon-wrapper">
              <img src={subject} alt="" height={15} width={15} />
              <div>Subject</div>
            </div>
            <div className="subject-class">{props.tableData?.subject}</div>
          </div>

          <div className="comment-header-wrapper">
            <div className="icon-wrapper">
              <img src={status} alt="" height={15} width={15} />
              <div>Status</div>
            </div>
            <div className="d-flex" style={{ gap: "20px" }}>
              <div className="status-value-container">
                {props.tableData?.status}
              </div>
              {/* <div className="priority-value-container">
                {props.tableData?.priority}
              </div> */}
            </div>
          </div>

          <div className="comment-header-wrapper">
            <div className="icon-wrapper">
              <img src={description} alt="" height={15} width={15} />
              <div>Description</div>
            </div>
          </div>
          <div className="services-admin-list-input">
            <div className="comment-description">
              {props.tableData?.description}
            </div>
          </div>

          <div className="comment-header-wrapper">
            <div className="icon-wrapper">
              <img src={attachment} alt="" height={15} width={15} />
              <div>Attachment</div>
            </div>
          </div>
          <div>
            {previews.map((preview, index) => (
              <div
                key={index}
                className="d-inline-block me-2 mb-2 position-relative"
              >
                {preview.type === "image" ? (
                  <a
                    href={preview.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={preview.url}
                      alt="Selected"
                      className="file-preview-image"
                    />
                  </a>
                ) : (
                  <a
                    href={preview.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="file-preview-icon">
                      {preview.type === "pdf" && (
                        <FaFilePdf size={50} color="#f00" />
                      )}
                      {preview.type === "word" && (
                        <i
                          className="bi bi-file-earmark-word"
                          style={{ fontSize: "50px", color: "#2b579a" }}
                        ></i>
                      )}
                      {preview.type === "excel" && (
                        <i
                          className="bi bi-file-earmark-excel"
                          style={{ fontSize: "50px", color: "#217346" }}
                        ></i>
                      )}
                      {preview.type === "csv" && (
                        <i
                          className="bi bi-file-earmark-spreadsheet"
                          style={{ fontSize: "50px", color: "#217346" }}
                        ></i>
                      )}
                      {preview.type === "file" && (
                        <i
                          className="bi bi-file-earmark"
                          style={{ fontSize: "50px", color: "#6c757d" }}
                        ></i>
                      )}
                    </div>
                  </a>
                )}
                <p className="file-name text-center">{preview.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="comment-section-body">
          {props.show && (
            <CommentSection
              initialComments={comments}
              ticketId={props.tableData?.ticket_id || ""}
              isSuccess={handlePostSuccess}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CommentBox;
