import { configureStore } from "@reduxjs/toolkit";
import Reducer from "./reducer";
import authReducer from "./authSlice";
import themeReducer from "./themeSlice";
import notificationReducer from "./notificationSlice";

export const store = configureStore({
  reducer: {
    dmon: Reducer,
    auth: authReducer,
    theme: themeReducer,
    notification: notificationReducer,
  },
});
