import React, { useEffect, useState } from "react";
import downloadImg from "../../../Assets/Images/export-icon.svg";
import EditImg from "../../../Assets/Images/edit-icon.svg";
import downDropImg from "../../../Assets/Images/down-drop.svg";
import lessThanImg from "../../../Assets/Images/less-than.svg";
import greaterThanImg from "../../../Assets/Images/greater-than.svg";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";

const Reports = () => {
  const [isOpen, setIsOpen] = useState(false); // Dropdown visibility
  const [selectedDevice, setSelectedDevice] = useState("Website Up/Down Time");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePage("report"));
  }, [dispatch]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (device) => {
    setSelectedDevice(device);
    setIsOpen(false); // Close dropdown
  };

  return (
    <div>
      {/* Header Section */}
      <div className="d-flex align-items-center justify-content-between my-2 respo-flex">
        <h4 className="main-title">Reports</h4>
        <button className="button-common button-icon secondary">
          <img
            src={downloadImg}
            alt="Download"
            style={{ width: "12px" }}
            className="me-2"
          />
          Export
        </button>
      </div>

      {/* Configuration Section */}
      <div className="bg-white bg-space-radius">
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h5 className="sub-title" style={{ color: "#082064" }}>
            Configuration
          </h5>
          <a href="#">
            <img src={EditImg} alt="Edit" />
          </a>
        </div>
        <div className="report-design-style">
          <div className="section-left">
            <div className="d-flex align-items-center">
              <p className="ques-left">Name of receiver:</p>
              <p className="ans-right">Ruby Thomas</p>
            </div>
            <div className="d-flex align-items-center">
              <p className="ques-left">Email ID:</p>
              <p className="ans-right">Admin@abc.com</p>
            </div>
            <div className="d-flex align-items-center">
              <p className="ques-left">Email Subject:</p>
              <p className="ans-right">Everyday Report - DMON</p>
            </div>
            <div className="d-flex align-items-center">
              <p className="ques-left">Email Body:</p>
              <p className="ans-right">
                This mail contains an attachment of the daily report.
              </p>
            </div>
          </div>
          <div className="section-right">
            <div className="d-flex align-items-center">
              <p className="ques-left">Time of sending:</p>
              <p className="ans-right">9.00 am</p>
            </div>
            <div className="d-flex align-items-center">
              <p className="ques-left">Time Period:</p>
              <p className="ans-right">Last 24 hrs</p>
            </div>
            <div className="d-flex align-items-center">
              <p className="ques-left">Frequency:</p>
              <p className="ans-right">Everyday</p>
            </div>
          </div>
        </div>
      </div>

      {/* Dropdown Section */}
      <div className="bg-white bg-space-radius mt-4">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <h6 className="sub-title">Website Up/Down Time</h6>
          <div className="" style={{ position: "relative" }}>
            <button
              className="btn dropdown-devices"
              type="button"
              onClick={toggleDropdown}
              style={{
                position: "relative",
                display: "flex",
                border: "2px solid #082064",
                borderRadius: "25px",
                cursor: "pointer",
                background: "#fff",
                width: "260px",
                justifyContent: "space-between",
              }}
            >
              <span>{selectedDevice}</span>
              <div className="dropdown-icon">
                <img
                  src={downDropImg}
                  alt="Dropdown"
                  style={{ width: "13px" }}
                />
              </div>
            </button>

            {isOpen && (
              <ul
                className=""
                style={{
                  listStyle: "none",
                  margin: "0",
                  padding: "0",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  position: "absolute",
                  top: "100%",
                  left: "0",
                  backgroundColor: "white",
                  zIndex: 1000,
                  width: "100%",
                  height: "265px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {[
                  "Website Up/Down Time",
                  "APC UPS - Bypass Status",
                  "APC UPS Battery Level",
                  "Hikvision IP Camera Status",
                  "APC UPS - Battery Voltage",
                  "APC UPS - Battery Temperature",
                  "APC UPS - Output Voltage",
                  "APC UPS - UPS Status",
                  "APC UPS - Runtime Remaining",
                  "Connectivity",
                  "Website Response Time",
                  "APC UPS - Load Percentage",
                  "Hikvision IP Camera Free Memory",
                  "APC UPS - Input Voltage",
                  "APC UPS - Battery Charge Status",
                  "SOPHOS Port Download Speed",
                  "SOPHOS Port Upload Speed",
                ].map((item, index) => (
                  <li key={index}>
                    <button
                      className="dropdown-item"
                      onClick={() => handleItemClick(item)}
                      style={{
                        border: "none",
                        background: "none",
                        width: "100%",
                        textAlign: "left",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {item}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Table Section */}
        <div className="table-responsive mt-4">
          <table className="table text-start table-style">
            <thead>
              <tr className="table-header">
                <th>SLA Instance</th>
                <th style={{ minWidth: "185px" }}>Status</th>
                <th>Downtime</th>
                <th>Uptime</th>
                <th>Up%</th>
              </tr>
            </thead>
            <tbody>
              {[10, 20, 50, 80, 100].map((value, index) => (
                <tr key={index}>
                  <td>URL {index + 1}</td>
                  <td>
                    <div className="progress-bar__wrapper">
                      <label
                        className="progress-bar__value"
                        htmlFor={`progress-bar-${index}`}
                      >
                        {value}%
                      </label>
                      <progress
                        id={`progress-bar-${index}`}
                        value={value}
                        max="100"
                      ></progress>
                    </div>
                  </td>
                  <td>00:00:00</td>
                  <td>72:00:00</td>
                  <td>100.00%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a className="page-link border-0" href="#" aria-label="Previous">
                <span aria-hidden="true">
                  <img src={lessThanImg} alt="Previous" />
                </span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link active" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link border-0" href="#" aria-label="Next">
                <span aria-hidden="true">
                  <img src={greaterThanImg} alt="Next" />
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Reports;
