import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "../../../Components/Button";
import DatePicker from "react-datepicker"; // Use a date-picker library
import "react-datepicker/dist/react-datepicker.css";

const FilterPopup = ({ onApplyFilters, ...props }) => {
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState(false);

  const validateFilters = () => {
    if ((startDate && !endDate) || (!startDate && endDate)) {
      setError("Both start date and end date must be selected.");
      return false;
    }

    if (startDate && endDate && startDate > endDate) {
      setDateError(true);
      setError("Start date must be less than or equal to end date.");
      return false;
    }

    setError("");
    setDateError(false);
    return true;
  };

  const handleApply = (e) => {
    e.preventDefault();
    const formatDate = (date) => {
      if (!date) return null; // Handle null or undefined
      return new Date(date).toISOString().split("T")[0];
    };
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    if (validateFilters()) {
      onApplyFilters({
        name,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      props.onHide(); // Close modal
    }
  };

  const handleClear = () => {
    setName("");
    setStartDate(null);
    setEndDate(null);
    setError("");
    onApplyFilters({
      name: "",
      startDate: null,
      endDate: null,
    }); // Reset filters in parent
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              // value={form.name}
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          {/* Date Range Filter */}
          <Form.Group className="mb-3">
            <Form.Label>Date Range</Form.Label>
            <div className="d-flex" style={{ gap: "10px" }}>
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  isClearable
                />
                {error && startDate === null && (
                  <p className="text-danger">{error}</p>
                )}
              </div>
              <div>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  isClearable
                />
                {error && endDate === null && (
                  <p className="text-danger">{error}</p>
                )}
              </div>
            </div>
            {dateError && (
              <p className="text-danger">Start Date is Greater than End Date</p>
            )}
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button type="primary" label="Apply" onClick={handleApply} />
            <Button
              type="secondary"
              label="Clear"
              onClick={handleClear}
              buttonMode="button"
            />
            <Button onClick={props.onHide} label="Cancel" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default FilterPopup;
