import React, { useState, useEffect } from "react";
import "./style.css";
import attachment from "../../Assets/Images/attachment.svg"; // Keep your imported styles
import { FaFilePdf } from "react-icons/fa"; // Example of other icon imports
import { postComment, fetchComments } from "../../Service"; // Import fetchComments API

const CommentInput = ({ placeholder, onSubmit, parentCommentId, isAdmin }) => {
  const [text, setText] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [isInternal, setIsInternal] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to Array
    setAttachments(selectedFiles);
  };

  const handleSubmit = async () => {
    if (text.trim() === "" && attachments.length === 0) return; // Prevent empty submission

    try {
      await onSubmit(text.trim(), attachments, parentCommentId, isInternal); // Submit with `isInternal`
      setText(""); // Clear text
      setAttachments([]); // Clear attachments
      setIsInternal(false); // Reset checkbox
    } catch (error) {
      alert("Failed to post comment.");
    }
  };

  return (
    <div className="comment-input-container">
      {/* File Previews */}
      <div className="file-previews">
        {attachments.map((file, index) => (
          <div key={index} className="file-preview">
            {file.type.startsWith("image/") ? (
              <img
                src={URL.createObjectURL(file)}
                alt="Preview"
                className="file-preview-image"
              />
            ) : (
              <div className="file-preview-icon">
                {file.name.endsWith(".pdf") && (
                  <FaFilePdf size={50} color="#f00" />
                )}
                <p className="file-name">{file.name}</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* File Input */}
      <div className="attachment-icon-wrapper">
        <label htmlFor="file-upload" className="attachment-label">
          <img src={attachment} alt="Attach file" className="attachment-icon" />
        </label>
        <input
          type="file"
          id="file-upload"
          multiple
          onChange={handleFileChange}
          className="file-input-hidden"
        />
      </div>

      {/* Text Input */}
      <input
        type="text"
        value={text}
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
        className="comment-input"
      />

      {/* Checkbox for My Side Note (Only for Admins) */}
      {isAdmin && (
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={isInternal}
            onChange={(e) => setIsInternal(e.target.checked)}
          />
          My Side Note
        </label>
      )}

      <button onClick={handleSubmit} className="comment-button">
        Post
      </button>
    </div>
  );
};

const Comment = ({ comment, onReply, isAdmin }) => {
  const {
    comment_id,
    sender_id,
    sender_type,
    message,
    created_at,
    replies,
    attachments,
    is_internal,
  } = comment;

  const [showReplyInput, setShowReplyInput] = useState(false);

  return (
    <div className={`comment ${is_internal ? "internal-comment" : ""}`}>
      <div className="comment-header">
        <div className="profile-picture">{sender_id[0]}</div>
        <div>
          <div className="comment-user">
            {sender_id} ({sender_type})
          </div>
          <div className="comment-timestamp">{created_at}</div>
        </div>
      </div>
      <div className="comment-text">{message}</div>

      {/* Render Attachments */}
      {attachments && attachments.length > 0 && (
        <div className="comment-attachments">
          {attachments.map((file, index) => {
            // Same logic for handling attachments
          })}
        </div>
      )}

      <div
        className="reply-link"
        onClick={() => setShowReplyInput(!showReplyInput)}
      >
        Reply
      </div>

      {/* Render Reply Input */}
      {showReplyInput && (
        <div className="replies">
          <CommentInput
            placeholder="Add a reply..."
            onSubmit={onReply}
            parentCommentId={comment_id}
            isAdmin={isAdmin}
          />
        </div>
      )}

      {/* Render Replies */}
      {replies && replies.length > 0 && (
        <div className="replies">
          {replies.map((reply) => (
            <Comment
              key={reply.comment_id}
              comment={reply}
              onReply={onReply}
              isAdmin={isAdmin}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const CommentSection = ({ initialComments, ticketId, isSuccess, isAdmin }) => {
  const [comments, setComments] = useState(initialComments);

  // Sync comments state with initialComments prop
  useEffect(() => {
    setComments(initialComments);
  }, [initialComments]);

  const addComment = async (
    message,
    attachments,
    parentCommentId = null,
    isInternal = false
  ) => {
    try {
      await postComment(
        ticketId,
        message,
        attachments,
        parentCommentId,
        isInternal
      ); // Include `isInternal`
      isSuccess();
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  return (
    <div className="comment-section">
      {comments.map((comment) => (
        <Comment
          key={comment.comment_id}
          comment={comment}
          onReply={addComment}
          isAdmin={isAdmin}
        />
      ))}
      <CommentInput
        placeholder="Add a comment..."
        onSubmit={addComment}
        parentCommentId={null} // Top-level comment
        isAdmin={isAdmin}
      />
    </div>
  );
};

export default CommentSection;
