import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "../../../Components/Button";
import DatePicker from "react-datepicker"; // Use a date-picker library
import "react-datepicker/dist/react-datepicker.css";

const FilterPopup = ({ onApplyFilters, ...props }) => {
  const [priority, setPriority] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState(false);

  const validateFilters = () => {
    if ((startDate && !endDate) || (!startDate && endDate)) {
      setError("Both start date and end date must be selected.");
      return false;
    }

    if (startDate && endDate && startDate > endDate) {
      setDateError(true);
      setError("Start date must be less than or equal to end date.");
      return false;
    }

    setError("");
    setDateError(false);
    return true;
  };

  const handleApply = (e) => {
    e.preventDefault();
    const formatDate = (date) => {
      if (!date) return null; // Handle null or undefined
      return new Date(date).toISOString().split("T")[0];
    };
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    if (validateFilters()) {
      onApplyFilters({
        priority,
        status,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      props.onHide(); // Close modal
    }
  };

  const handleClear = () => {
    setPriority("");
    setStatus("");
    setStartDate(null);
    setEndDate(null);
    setError("");
    onApplyFilters({
      priority: "",
      status: "",
      startDate: null,
      endDate: null,
    }); // Reset filters in parent
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Form>
          {/* Priority Filter */}
          <Form.Group className="mb-3">
            <Form.Label>Priority</Form.Label>
            <Form.Control
              as="select"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              <option value="">All</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </Form.Control>
          </Form.Group>

          {/* Status Filter */}
          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value="New">New</option>
              <option value="Pending">Pending</option>
              <option value="On hold">On Hold</option>
              <option value="Resolved">Resolved</option>
              <option value="Closed">Closed</option>
              <option value="Cancelled">Cancelled</option>
            </Form.Control>
          </Form.Group>

          {/* Date Range Filter */}
          <Form.Group className="mb-3">
            <Form.Label>Date Range</Form.Label>
            <div className="d-flex" style={{ gap: "10px" }}>
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  isClearable
                />
                {error && startDate === null && (
                  <p className="text-danger">{error}</p>
                )}
              </div>
              <div>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  isClearable
                />
                {error && endDate === null && (
                  <p className="text-danger">{error}</p>
                )}
              </div>
            </div>
            {dateError && (
              <p className="text-danger">Start Date is Greater than End Date</p>
            )}
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button type="primary" label="Apply" onClick={handleApply} />
            <Button
              type="secondary"
              label="Clear"
              onClick={handleClear}
              buttonMode="button"
            />
            <Button onClick={props.onHide} label="Cancel" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default FilterPopup;
