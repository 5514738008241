import React, { useEffect, useState } from "react";
import { getCustomerDetails } from "../../../Service";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import "./style.css";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router-dom";
import logoBlue from "../../../Assets/Images/logo_blue.png";

const Profile = () => {
  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const dispatch = useDispatch();
  const goto = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      setIsLoaded(false);
      try {
        const data = await getCustomerDetails();
        setData(data.customer_data);
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };

    dispatch(setActivePage("profile"));

    fetchProfileData();
  }, [dispatch]);

  useEffect(() => {
    console.log(data, "customer Data");
  }, [data]);
  return (
    <div className="bg-white bg-space-radius">
      {isLoaded && (
        <>
          <div className="d-flex align-items-center justify-content-between w-100 mb-4">
            <div className="d-flex align-items-center">
              <div className="logo-style">
                <img src={logoBlue} alt="" />
              </div>
              <div className="company-name">
                <h4>Dcube Ai</h4>
                <p className="mb-0">www.dcubeai.com</p>
              </div>
            </div>
            <div>
              <button
                className="btn btn-success"
                onClick={() => {
                  goto("/client/edit-profile");
                }}
              >
                Edit
              </button>
            </div>
          </div>
          <div className="profile-section">
            <h3>Company Profile</h3>
            <div className="profile-space">
              <div className="profile-left ">
                <label>Company</label>
                <p>Dcube Ai</p>
              </div>
              <div className="profile-right">
                <label>Phone Number</label>
                <p>09 345 346 46</p>
              </div>
            </div>
            <div className="profile-space">
              <div className="profile-left">
                <label>website</label>
                <p>www.dcubeai.com</p>
              </div>
              <div className="profile-right">
                <label>Email id</label>
                <p>mail@dcubeai.com</p>
              </div>
            </div>
            <div className="profile-space">
              <div className="profile-left">
                <label>Address 1</label>
                <p>
                  #2702, 7th Floor, Yamuna Building SEZ Campus, Phase III,
                  Technopark, Trivandrum, Kerala 695 583
                </p>
              </div>
              <div className="profile-right">
                <label>Address 2</label>
                <p>R & D Centre, Ceebees, Varkala, Trivandrum 695 141</p>
              </div>
            </div>

            {/* <div className="profile-heading">{`${data.first_name} ${data.last_name}`}</div>
              <div className="profile-detail-wraper">
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="profile-label">Email</div>
                  <div>:</div>
                  <div className="profile-value">{data.email}</div>
                </div>

                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="profile-label">Mobile</div>
                  <div>:</div>
                  <div className="profile-value">{data.phone}</div>
                </div>

                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="profile-label">City</div>
                  <div>:</div>
                  <div className="profile-value">{data.city}</div>
                </div>

                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="profile-label">Country</div>
                  <div>:</div>
                  <div className="profile-value">{data.country}</div>
                </div>
              </div> */}
            {/* </div> */}
          </div>
          {/* <div className="button-alignment">
            <Button
              label="Edit Profile"
              type="primary"
              onClick={() => {
                goto("/client/edit-profile");
              }}
            />
          </div> */}
        </>
      )}
    </div>
  );
};

export default Profile;
