import React from "react";
import "./style.css";
import { Outlet } from "react-router-dom";
import SidemenuAdmin from "../Components/SidemenuAdmin";
import SideMenuCustomer from "../Components/SideMenuCustomer";
import ThemeToggleButton from "../Components/ThemeToggleButton";
import { useSelector } from "react-redux";

const MainLayout = () => {
  const theme = useSelector((state) => state.theme.mode);
  const user = localStorage.getItem("user");
  return (
    <div className="layout-wraper">
      {user === "Admin" ? <SidemenuAdmin /> : <SideMenuCustomer />}
      <div className={`${theme === "light" ? "main-body" : "main-body-dark"}`}>
        <ThemeToggleButton />
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
