import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
// import { CssBaseline } from '@mui/material';
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import { Box, IconButton, Tooltip, ThemeProvider, createTheme } from "@mui/material";
import Button from "../../../Components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import { Form } from "react-bootstrap";
import {
  deleteCustomerDevice,
  enableDevice,
  getCustomerDevice,
} from "../../../Service";
import MyDevicePopup from "./MyDevicePopup";
import AlertBox from "../../../Components/AlertBox";
import { formatDate } from "../../../Utils";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#161C29',
      paper: '#1c1c1e !important',
    },
    text: {
      primary: '#e0e0e0',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1c1c1e !important',
          '& .MuiBox-root': {
            backgroundColor: '#1c1c1e !important',
        },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
        },
      },
    },
    MuiTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important', // Dark background for header
          color: '#e0e0e0',           // Text color in dark mode
          borderBottom: '1px solid rgba(81, 81, 81, 0.5)',
          
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& td': {
              position: 'relative', 
              '&:after': {
                content: '""', 
                backgroundColor: '#242425 !important', 
              },
            },
        },
      },
    },
  },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
          borderBottom: '1px solid #373737',
          color: '#e0e0e0', // Ensure text color is visible
          '& .MuiBox-root': {
            backgroundColor: '#242425 !important',
          },
        },
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff', // Light background for the app
      paper: '#f5f5f5',   // Light background for paper elements like the table
    },
    text: {
      primary: '#000000', // Dark text color for light mode
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5', // Light background for table container
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent', // Table cell background remains transparent
        },
      },
    },
  },
});

const MyDevice = () => {
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [mode, setMode] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [tableData, setTableData] = useState("");
  const [deleteData, setDeleteData] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const theme = useSelector((state) => state.theme.mode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePage("my-device"));
  }, [dispatch]);

  useEffect(() => {
    const fetchDeviceList = async () => {
      try {
        const data = await getCustomerDevice();
        setData(data.device_data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDeviceList();
  }, [toggle, isAdded]);

  function added() {
    setIsAdded(!isAdded);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "device_id",
        header: "Device Id",
        size: 80,
      },
      {
        accessorKey: "device_alias",
        header: "Alias",
      },
      {
        accessorKey: "task_count",
        header: "Tasks in 24 hrs",
      },
      {
        accessorKey: "device_status",
        header: "Status",
        Cell: ({ cell }) => (
          <span
            className={`${cell.getValue() === "Online" ? "online" : "offline"}`}
          >
            {cell.getValue()}
          </span>
        ),
      },
      {
        accessorKey: "created_at",
        header: "Registered Date",
        accessorFn: (row) => new Date(row.created_at), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
      {
        accessorKey: "last_awake",
        header: "Last Awake",
        accessorFn: (row) => new Date(row.last_awake), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "end",
          minWidth: "100px",
          width: "120px",
        }}
      >
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch-1"
            checked={row.original.action === 1}
            label=""
            className=""
            onChange={async (e) => {
              await enableDevice({
                action: e.target.checked ? 1 : 0,
                device_id: row.original.device_id,
              });
              setToggle(!toggle);
            }}
          />
        </Form>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setMode("edit");
              setTableData(row.original);
              setModalShow(true);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  await deleteCustomerDevice({
                    device_ids: [row.original.device_id],
                  });
                  setAlert(false);
                  added();
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{ gap: "15px" }}>
        <Button
          icon={addIcon}
          label="Add"
          type="primary"
          onClick={() => {
            setMode("add");
            setModalShow(true);
          }}
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.device_id;
              });
              setDeleteData({
                onClick: async () => {
                  try {
                    await deleteCustomerDevice({ device_ids: newArray });
                    setAlert(false);
                    setRowSelection({});
                    added();
                  } catch {}
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}
      </div>
    ),
  });
  return (
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        {/* <CssBaseline /> */}
          <div>
            <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
              <h4 className="sub-title">My Devices</h4>
            </div>
            <MaterialReactTable table={table} />
          </div>
          <MyDevicePopup
            show={modalShow}
            tableData={tableData}
            mode={mode}
            added={added}
            onHide={() => setModalShow(false)}
            />
          <AlertBox
            show={alert}
            onHide={() => setAlert(false)}
            deleteData={deleteData}
            />
       
      </ThemeProvider>
  );
};

export default MyDevice;
