import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import monitor from "../../../Assets/Images/devices-admin-icon.svg";
import deviceIcon from "../../../Assets/Images/device-per-icon.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import { Box, IconButton, Tooltip, ThemeProvider, createTheme } from "@mui/material";
import Button from "../../../Components/Button";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  deleteDeviceList,
  enableDevice,
  getDeviceList,
} from "../../../Service";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import RadialSeparators from "./RadialSeparators";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import DevicePopup from "./DevicePopup";
import AlertBox from "../../../Components/AlertBox";
import { formatDate } from "../../../Utils";


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#161C29',
      paper: '#1c1c1e !important',
    },
    text: {
      primary: '#e0e0e0',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1c1c1e !important',
          '& .MuiBox-root': {
            backgroundColor: '#1c1c1e !important',
        },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
        },
      },
    },
    MuiTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important', // Dark background for header
          color: '#e0e0e0',           // Text color in dark mode
          borderBottom: '1px solid rgba(81, 81, 81, 0.5)',
          
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& td': {
              position: 'relative', 
              '&:after': {
                content: '""', 
                backgroundColor: '#242425 !important', 
              },
            },
        },
      },
    },
  },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
          borderBottom: '1px solid #373737',
          color: '#e0e0e0', // Ensure text color is visible
          '& .MuiBox-root': {
            backgroundColor: '#242425 !important',
          },
        },
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff', // Light background for the app
      paper: '#f5f5f5',   // Light background for paper elements like the table
    },
    text: {
      primary: '#000000', // Dark text color for light mode
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5', // Light background for table container
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent', // Table cell background remains transparent
        },
      },
    },
  },
});

const Devices = () => {
  const [data, setData] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [toggle, setToggle] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [mode, setMode] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [tableData, setTableData] = useState("");
  const [deleteData, setDeleteData] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const theme = useSelector((state) => state.theme.mode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePage("device"));
  }, [dispatch]);

  useEffect(() => {
    const fetchDeviceList = async () => {
      try {
        const data = await getDeviceList();
        setData(data.device_data);
        setStatistics(data.statistics);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDeviceList();
  }, [toggle, isAdded]);

  function added() {
    setIsAdded(!isAdded);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "device_id",
        header: "Device Id",
        size: 80,
      },
      {
        accessorKey: "device_alias",
        header: "Alias",
      },
      {
        accessorKey: "customer_name",
        header: "Customer Name",
        accessorFn: (row) =>
          `${row.customer_first_name ? row.customer_first_name : "-"} ${
            row.customer_last_name ? row.customer_last_name : ""
          }`,
      },
      {
        accessorKey: "device_status",
        header: "Status",
        Cell: ({ cell }) => (
          <span
            className={`${cell.getValue() === "Online" ? "online" : "offline"}`}
          >
            {cell.getValue()}
          </span>
        ),
      },
      {
        accessorKey: "last_awake",
        header: "Last Awake",
        accessorFn: (row) => (row.last_awake ? new Date(row.last_awake) : null), // Use Date object for sorting or return null
        Cell: ({ cell }) => {
          const dateValue = cell.getValue();
          return dateValue ? formatDate(dateValue) : "-"; // Show "-" if null or invalid date
        },
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "end",
          minWidth: "100px",
          width: "120px",
        }}
      >
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch-1"
            checked={row.original.action === 1}
            label=""
            className=""
            onChange={async (e) => {
              await enableDevice({
                action: e.target.checked ? 1 : 0,
                device_id: row.original.device_id,
              });
              setToggle(!toggle);
            }}
          />
        </Form>

        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setMode("edit");
              setTableData(row.original);
              setModalShow(true);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  try {
                    await deleteDeviceList({
                      devices: [row.original.device_id],
                    });
                    setAlert(false);
                    added();
                  } catch {}
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{ gap: "15px" }}>
        <Button
          icon={addIcon}
          label="Add"
          type="primary"
          onClick={() => {
            setMode("add");
            setModalShow(true);
          }}
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.device_id;
              });
              setDeleteData({
                onClick: async () => {
                  await deleteDeviceList({ devices: newArray });
                  setAlert(false);
                  setRowSelection({});
                  added();
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}
      </div>
    ),
  });

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
        <h4 className="sub-title">Devices</h4>
      </div>
      <div className="devices-performance">
        <div className="bg-white bg-space-radius d-flex align-items-center justify-content-between">
          {/*  */}
          <div className="d-flex align-items-center flex-column justify-content-between h-100">
            <div className="w-100 d-flex align-items-center justify-content-start">
              <div
                className="monitorig-icon-style"
                style={{ margin: "initial" }}
              >
                <img src={monitor} alt="" />
              </div>
              <h5 className="mt-3 ms-3"> Monitoring Task</h5>
            </div>
            {/*  */}
            <div>
              <div className="d-flex align-items-center me-4">
                <div
                  className="box-devices"
                  style={{ background: "#32A9C7" }}
                ></div>
                <div className="mb-0 ms-2 box-devices-content">
                  Total Device : {statistics.total_devices}
                </div>
              </div>

              <div className="d-flex align-items-center me-4">
                <div
                  className="box-devices"
                  style={{ background: "#9ae7f2" }}
                ></div>
                <div className="mb-0 ms-2 box-devices-content">
                  Total Active Devices : {statistics.total_online_devices}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="piechart-wrapper" style={{ position: "relative" }}>
              <div style={{ width: 160 }}>
                {/* <CircularProgressbarWithChildren
                value={statistics.total_online_devices || 0}
                maxValue={statistics.total_devices}
                // text={`${statistics.online_percentage || 0}%`}
                styles={buildStyles({
                  pathColor: "#BBFB94",
                  textColor: "#000",
                  trailColor: "#36C3CE",
                })}
              >
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    // padding: "10px",
                    fontSize: 10,
                    color: "#000",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      borderBottom: "1px solid #e7e7e7",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                      }}
                    >
                      Online
                    </div>
                    <strong>{`${statistics.total_online_devices || 0}`}</strong>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <div style={{ fontSize: 12, opacity: 0.6 }}>
                      {`${statistics.total_devices || 0}`}
                    </div>
                    <div>Total Device</div>
                  </div>
                </div>
              </CircularProgressbarWithChildren> */}

                <CircularProgressbarWithChildren
                  value={statistics.total_online_devices || 0}
                  maxValue={statistics.total_devices}
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#32A9C7",
                    textColor: "#082064",
                    trailColor: "#9ae7f2",
                  })}
                >
                  <RadialSeparators
                    count={12}
                    style={{
                      background: "#fff",
                      width: "3px",
                      // This needs to be equal to props.strokeWidth
                      height: `${10}%`,
                    }}
                  />
                </CircularProgressbarWithChildren>
              </div>
              {/*  */}
              <div
                className="d-flex align-items-start flex-column"
                style={{ position: "absolute" }}
              >
                <div
                  className="d-flex align-items-center justify-content-between w-100"
                  style={
                    {
                      // display: "grid",
                      // placeItems: "center",
                      // borderBottom: "1px solid #e7e7e7",
                    }
                  }
                >
                  <div
                    style={{
                      fontSize: 14,
                    
                    }}
                    className="box-devices-content"
                  >
                    Online:
                  </div>
                  <strong
                    className="ms-3 box-devices-content"
                    style={{
                      fontSize: 14,
                     
                    }}
                  >{`${statistics.total_online_devices || 0}`}</strong>
                </div>
                <div
                  className="d-flex align-items-center justiify-content-between w-100"
                  style={
                    {
                      // display: "grid",
                      // placeItems: "center",
                    }
                  }
                >
                  <div
                    style={{
                      fontSize: 14,
                     
                    }}
                    className="box-devices-content"
                  >
                    Total Device:
                  </div>
                  <strong
                    className="ms-3 box-devices-content"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {`${statistics.total_devices || 0}`}
                  </strong>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
          {/*  */}
        </div>

        <div className="bg-white bg-space-radius d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center flex-column justify-content-between h-100">
            <div className="w-100 d-flex align-items-center justify-content-start">
              <div
                className="monitorig-icon-style"
                style={{ margin: "initial" }}
              >
                <img src={deviceIcon} alt="" />
              </div>
              <h5 className="mt-3 ms-3">Devices</h5>
            </div>
            {/*  */}
            {/* content */}
            <div>
              <div className="d-flex align-items-center me-4">
                <div
                  className="box-devices"
                  style={{ background: "#24D4B0" }}
                ></div>
                <div className="mb-0 ms-2 box-devices-content">
                  Active : {statistics.online_percentage}
                </div>
              </div>

              <div className="d-flex align-items-center me-4">
                <div
                  className="box-devices"
                  style={{ background: "#b7e4db" }}
                ></div>
                <div className="mb-0 ms-2 box-devices-content">
                  Offline : {statistics.offline_percentage}
                </div>
              </div>
            </div>
            {/* end content */}
          </div>
          {/*  */}
          {/* chart */}
          <div>
            <div className="piechart-wrapper">
              <div style={{ width: 160 }}>
                {/* <CircularProgressbar
                value={statistics.online_percentage || 0}
                maxValue={100}
                // text={`${statistics.online_percentage || 0}%`}
                styles={buildStyles({
                  // pathColor: "#BBFB94",
                  // textColor: "#000",
                  // trailColor: "#6D7AA7",
                })}
              /> */}

                <CircularProgressbarWithChildren
                  value={statistics.online_percentage || 0}
                  // value={80}
                  text={`${statistics.online_percentage || 0}%`}
                  maxValue={100}
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#24D4B0",
                    trailColor: "#b7e4db",
                  })}
                  className="box-devices-content"
                >
                  <RadialSeparators
                    count={12}
                    style={{
                      background: "#fff",
                      width: "3px",
                      // This needs to be equal to props.strokeWidth
                      height: `${10}%`,
                    }}
                  />
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
          {/* end chart */}
        </div>
      </div>
      <MaterialReactTable table={table} />
      <DevicePopup
        show={modalShow}
        mode={mode}
        added={added}
        tableData={tableData}
        onHide={() => setModalShow(false)}
      />
      <AlertBox
        show={alert}
        onHide={() => setAlert(false)}
        deleteData={deleteData}
      />
    </div>
    </ThemeProvider>
  );
};

export default Devices;
