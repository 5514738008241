import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import comment from "../../../Assets/Images/comment.svg";
import {
  Box,
  IconButton,
  Tooltip,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Button from "../../../Components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import { deleteCustomerTicket, getCustomerTicket } from "../../../Service";
// import NodesPopup from "./NodesPopup";
import AlertBox from "../../../Components/AlertBox";
import { formatDate } from "../../../Utils";
import { useNavigate } from "react-router-dom";
import ReadMore from "../../../Components/ReadMore";
import CommentBox from "./CommentBox";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#161C29",
      paper: "#1c1c1e !important",
    },
    text: {
      primary: "#e0e0e0",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1c1c1e !important",
          "& .MuiBox-root": {
            backgroundColor: "#1c1c1e !important",
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
        },
      },
    },
    MuiTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important", // Dark background for header
          color: "#e0e0e0", // Text color in dark mode
          borderBottom: "1px solid rgba(81, 81, 81, 0.5)",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& td": {
              position: "relative",
              "&:after": {
                content: '""',
                backgroundColor: "#242425 !important",
              },
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#242425 !important",
          borderBottom: "1px solid #373737",
          color: "#e0e0e0", // Ensure text color is visible
          "& .MuiBox-root": {
            backgroundColor: "#242425 !important",
          },
        },
      },
    },
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#ffffff", // Light background for the app
      paper: "#f5f5f5", // Light background for paper elements like the table
    },
    text: {
      primary: "#000000", // Dark text color for light mode
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5", // Light background for table container
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent", // Table cell background remains transparent
        },
      },
    },
  },
});

const RaiseTicket = () => {
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [ticketData, setTicketData] = useState();
  const [modalShowComment, setModalShowComment] = useState(false);
  const theme = useSelector((state) => state.theme.mode);

  const goto = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const data = await getCustomerTicket();
        setData(data.tickets);
      } catch (error) {
        console.log(error);
      }
    };
    dispatch(setActivePage("ticket"));
    fetchCustomerList();
  }, [dispatch, isAdded]);

  function added() {
    setIsAdded(!isAdded);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "ticket_id",
        header: "Ticket ID",
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ row }) => {
          return (
            <>
              <div style={{ fontWeight: "bolder" }}>{row.original.subject}</div>
              <ReadMore
                text={row.original.description}
                heading={row.original.subject}
              />
              {/* <div>{row.original.description}</div> */}
            </>
          );
        },
      },
      {
        accessorKey: "created_at",
        header: "Created On",
        accessorFn: (row) => new Date(row.created_at), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "updated_at",
        header: "Last Updated",
        accessorFn: (row) => new Date(row.updated_at), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box
        sx={{ display: "flex", gap: "1rem", minWidth: "100px", width: "100px" }}
      >
        <Tooltip title="Comments">
          <IconButton
            onClick={() => {
              // setMode("edit");
              setTicketData(row.original);
              setModalShowComment(true);
            }}
          >
            <img src={comment} alt="edit" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              goto(`/client/submit-ticket/${row.original.ticket_id}`);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  await deleteCustomerTicket({
                    ticket_ids: [row.original.ticket_id],
                  });
                  setAlert(false);
                  added();
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{ gap: "15px" }}>
        <Button
          onClick={() => {
            goto("/client/submit-ticket/add");
          }}
          icon={addIcon}
          label="Add"
          type="primary"
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.ticket_id;
              });
              setDeleteData({
                onClick: async () => {
                  await deleteCustomerTicket({ ticket_ids: newArray });
                  setAlert(false);
                  setRowSelection({});
                  added();
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}
      </div>
    ),
  });
  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h4 className="sub-title">Raise Tickets</h4>
        </div>
        <MaterialReactTable table={table} />
      </div>

      <AlertBox
        show={alert}
        onHide={() => setAlert(false)}
        deleteData={deleteData}
      />

      <CommentBox
        show={modalShowComment}
        onHide={() => setModalShowComment(false)}
        tableData={ticketData}
      />
    </ThemeProvider>
  );
};

export default RaiseTicket;
