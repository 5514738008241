import React, { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import Button from "../../Components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import authService from "../../Service/authService";

const Otp = () => {
  const [otp, setOtp] = React.useState("");
  const [countdown, setCountdown] = React.useState(60); // Timer starts from 60 seconds
  const [resend, setResend] = useState(false);

  const location = useLocation();
  const email = location.state?.email;

  const goto = useNavigate();

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // Countdown timer
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev > 0) return prev - 1;
        clearInterval(timer); // Clear timer when it reaches 0
        return 0;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [resend]);

  // Format time in MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${mins}:${secs}`;
  };

  return (
    <div className="inside-right-box">
      <div className="text-center mb-4">
        <h2>OTP Verification</h2>
        <p className="col-lg-8 m-auto mb-4">
          Enter the code from the same we sent to {email}
        </p>
        <p
          style={{ color: "#082064", fontWeight: "600" }}
          className="text-center"
        >
          {formatTime(countdown)}
        </p>
      </div>
      <MuiOtpInput length={6} value={otp} onChange={handleChange} />
      <div className="mt-4">
        <p>
          Didn't receive the email?
          <span
            className={`${
              countdown === 0 ? "otp-resend-active" : "otp-resend-disable"
            }`}
            onClick={
              countdown === 0
                ? async () => {
                    try {
                      await authService.passwordResetEmail({
                        email,
                        is_resend: true,
                      });
                      setCountdown(60);
                      setResend(!resend);
                    } catch (err) {
                      console.log(err);
                    }
                  }
                : () => {}
            }
          >
            RESEND
          </span>
        </p>
      </div>
      <div
        className="d-flex justify-content-end"
        style={{ gap: "15px", paddingTop: "20px" }}
      >
        <Button
          type="primary"
          label="Submit"
          buttonMode="submit"
          onClick={async () => {
            try {
              await authService.otpVerfication({ email, reset_code: otp });
              goto("/new-password");
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Otp;
