import React, { useMemo } from "react";
import { MRT_Table, useMaterialReactTable } from "material-react-table";
import { createTheme } from "@mui/material";
import { useSelector } from "react-redux";

const TopTenChart = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "rank",
        header: "#",
        size: 50,
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "value",
        header: "Alert Sent",
        size: 50,
      },
    ],
    []
  );

  // const dummyData = [
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  //   {rank:1, name:"Sample", value:"2"},
  // ]
  // const theme = createTheme({
  //   palette: {
  //     mode: "dark", // Switch to "light" for light mode
  //     background: {
  //       default: "#121212",
  //       paper: "#1e1e1e",
  //     },
  //     text: {
  //       primary: "#e0e0e0",
  //       secondary: "#90caf9",
  //     },
  //   },
  // });
  const theme = useSelector((state) => state.theme.mode);
  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableTableHead: true, // Keeps table headers
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme === "dark" ? "#242425" : theme.palette.background.default, // Dark mode background
    }),
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme === "dark" ? "#242425 !important" : "rgba(0, 0, 0, 0.04) !important", 
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme === "dark" ? "#242425 !important" : "#fff !important", 
        },
      },
    },
    muiTableProps: {
      sx: {
        border: theme === "dark" ? "1px solid rgba(81, 81, 81, 0.5)" : "0px solid rgba(81, 81, 81, 0.5)",
        maxWidth: "100%", // Reduce table width
        fontSize: "0.85rem", // Smaller font size
        backgroundColor: theme === "dark" ? "#242425" : "inherit", 
        color: theme === "dark" ? "#e0e0e0" : "inherit",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: theme === "dark" ? "1px solid rgba(81, 81, 81, 0.5)" : "1px solid rgba(224, 224, 224, 1)",
        // fontStyle: "italic",
        fontWeight: "600",
        padding: "4px 8px", // Smaller padding
        // background: '#000',
        backgroundColor: theme === "dark" ? "#242425" : "inherit", // Dark header background
        color: theme === "dark" ? "#90caf9" : "#5578ba", // Light blue text in dark mode
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderBottom: theme === "dark" ? "1px solid rgba(81, 81, 81, 0.5)" : "1px solid rgba(224, 224, 224, 1)",
        padding: "4px 8px", // Smaller padding
        color: theme === "dark" ? "#e0e0e0" : "inherit", 
      },
    },
    muiTableBodyProps: {
      sx: {
        "& tr": {
          height: "32px", // Reduce row height
        },
        "&:hover": {
          backgroundColor:
            theme === "dark" ? "rgba(255, 255, 255, 0.08)" : "inherit", // Subtle hover effect for dark mode
        },
      },
    },
  });

  return (
    <div style={{ width: "100%", maxWidth: "100%", margin: "0 auto" }}>
      <MRT_Table table={table} />
    </div>
  );
};

export default TopTenChart;
