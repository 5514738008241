import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import authService from "../../Service/authService";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";

const initialForm = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});
const ForgetPassword = () => {
  const goto = useNavigate();
  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await authService.passwordResetEmail(values);
        resetForm();
        goto("/otp", { state: { email: values.email } });
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <div className="inside-right-box">
      <Form onSubmit={formik.handleSubmit}>
          <div className="mb-4 text-center" style={{color: '#000'}}>
              <h2 style={{color: '#000'}}>Forgot Password?</h2>
              <p>
                No worries, we'll send you reset instructions.
              </p>
            </div>
        <Form.Group className="mb-3 form-details" controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.email && formik.touched.email}
              className="ps-3"
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
          <Button type="primary" label="Reset Password" buttonMode="submit" />
        </div>
      </Form>
    </div>
  );
};

export default ForgetPassword;
