import React from "react";
import logo from "../Assets/Images/logo-white.png";
import { Outlet } from "react-router-dom";

const LoginLayout = () => {
  return (
    <div>
      <section className="login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="login-left h-100">
                <div className="d-flex justify-content-between align-items-start flex-column login-left-inside">
                  <div className="logo-white">
                    <img src={logo} alt="" />
                  </div>
                  <div className="inside-box">
                    <h1 className="text-uppercase">AWAKE LIKE AN OWL</h1>
                    <p>
                      Real-Time insights, reliable performance monitoring that
                      never sleeps.
                    </p>
                  </div>
                  <div className="footer-copyright text-center w-100">
                    <p className="mb-0 text-white">
                      Copyright &copy; 2024. All right reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 bg-white position-relative">
              <div className="inside-right">
                  <Outlet />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginLayout;
