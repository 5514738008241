import React, { useEffect, useState } from "react";
import attherate from "../../Assets/Images/attherate.svg";
import lock from "../../Assets/Images/lock.svg";
import { useNavigate } from "react-router-dom";
import authService from "../../Service/authService";
import RegisterForm from "./RegisterForm";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [isRegister, setIsRegister] = useState(false);

  const goto = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user === "Admin") {
      goto("/admin");
    } else if (user === "Customer") {
      goto("/client");
    }
  }, [goto]);

  useEffect(() => {
    console.log(isRegister, "Register");
  }, [isRegister]);

  function onChange(e) {
    const { name, value, type } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: type === "number" ? parseFloat(value) : value,
      };
    });
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const data = await authService.login(form.username, form.password);
      console.log(data, "Login Data");
      if (data.role === "Admin") goto("/admin");
      else goto("/client");
    } catch (err) {
      console.log(err, "Error");
      // toast.error(err.response.data.error, {
      //   position: "top-right",
      // });
    }
  }
  return (
    <>
      {!isRegister ? (
        // <div className="inside-right">
        <div className="inside-right-box">
          <form onSubmit={onSubmit}>
            <div className="mb-5" style={{color: '#000'}}>
              <h2 style={{color: '#000'}}>Login to your account</h2>
            </div>
            <div className="form-details text-start">
              <div className="mb-4 position-relative">
                <div className="icon-position">
                  <img src={attherate} alt="" height={18} width={18} />
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  aria-describedby="username"
                  placeholder="Enter your username here *"
                  required
                  value={form.username}
                  onChange={onChange}
                  name="username"
                />
              </div>
              <div className="mb-4 position-relative">
                <div className="icon-position">
                  <img src={lock} alt="" width={18} height={18} />
                </div>
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  className="form-control"
                  id="password"
                  placeholder="Enter your password here *"
                  required
                  value={form.password}
                  onChange={onChange}
                  name="password"
                />
                <div
                  className="icon-eye"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <i
                    className={`fa-regular ${
                      showPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                    id="iconToggles"
                  ></i>
                </div>
              </div>
              <div className="text-end">
                <p>
                  <div
                    className="text-decoration-underline cursor-pointer"
                    onClick={() => {
                      goto("/forgot-password");
                    }}
                    style={{color: '#000'}}
                  >
                    Forgot Password?
                  </div>
                </p>
              </div>
              <div className="w-100 mt-5">
                <button
                  type="submit"
                  className="btn btn-primary w-100 mb-4"
                  id="login-button"
                >
                  Login
                </button>
                <button
                  type="button"
                  onClick={() => setIsRegister(true)} // This correctly changes isRegister to true
                  className="btn btn-outline-secondary w-100"
                >
                  Register
                </button>
              </div>
            </div>
            <div></div>
            <div className="footer-copyrightss text-center w-100">
              <p className="mb-0 text-black">
                Copyright &copy; 2024. All right reserved.
              </p>
            </div>
          </form>
        </div>
      ) : (
        // </div>
        <RegisterForm goto={() => setIsRegister(false)} />
      )}
    </>
  );
};

export default Login;
