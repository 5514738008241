// themeSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Get the initial mode from localStorage or set it to "light" if not present
const getInitialMode = () => {
  const savedMode = localStorage.getItem("mode");
  return savedMode ? savedMode : "light";
};

const themeSlice = createSlice({
  name: "theme",
  initialState: { mode: getInitialMode() }, // Use the initial mode from localStorage
  reducers: {
    toggleTheme: (state) => {
      // Toggle the mode between "light" and "dark"
      state.mode = state.mode === "light" ? "dark" : "light";
      // Save the updated mode to localStorage
      localStorage.setItem("mode", state.mode);
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
