import React, { useEffect, useState } from "react";
import Button from "../../../../Components/Button";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../../Redux/reducer";
import { editCustomerDetails, getCustomerDetails } from "../../../../Service";
import { useNavigate } from "react-router-dom";
import logoBlue from "../../../../Assets/Images/logo_blue.png";
import addIcon from "../../../../Assets/Images/add-white.svg";

const initialForm = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  address_1: "",
  address_2: "",
  state: "",
  city: "",
  country: "",
  zipcode: "",
};
const EditProfileForm = () => {
  const [form, setForm] = useState(initialForm);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();
  const goto = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const data = await getCustomerDetails();
        setForm({
          email: data.customer_data.email,
          first_name: data.customer_data.first_name,
          last_name: data.customer_data.last_name,
          phone: data.customer_data.phone,
          address_1: data.customer_data.address_1,
          address_2: data.customer_data.address_2,
          state: data.customer_data.state,
          city: data.customer_data.city,
          country: data.customer_data.country,
          zipcode: data.customer_data.zipcode,
        });
      } catch (error) {
        console.log(error);
      }
    };

    dispatch(setActivePage("profile"));

    fetchProfileData();
  }, [dispatch]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(
          "https://countriesnow.space/api/v0.1/countries"
        );
        setCountry(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await axios.get(
          `https://countriesnow.space/api/v0.1/countries/states/q?country=${form.country}`
        );
        setState(response.data.data.states);
      } catch (err) {
        console.log(err);
      }
    };
    if (form.country !== "") fetchState();
  }, [form.country]);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get(
          `https://countriesnow.space/api/v0.1/countries/state/cities/q?country=${form.country}&state=${form.state}`
        );
        setCity(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (form.state !== "") fetchCity();
  }, [form.state]);

  function onChange(e) {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (!disabled) {
      setDisabled(true);
      try {
        const data = await editCustomerDetails(form);
        setForm(initialForm);
        goto("/client/profile");
      } catch (err) {
        console.log(err);
      } finally {
        setDisabled(false);
      }
    }
  }
  return (
    <div className="profile-section mt-4" style={{ height: "90%" }}>
      <div className="d-flex align-items-center justify-content-between w-100 mb-4">
        <div className="d-flex align-items-center">
          <div className="logo-style">
            <img src={logoBlue} alt="" />
            <div className="upload-img-profile">
              <img src={addIcon} alt="" />
            </div>
          </div>
          <div className="company-name">
            <h4>Dcube Ai</h4>
            <p className="mb-0">www.dcubeai.com</p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-cancel"
            onClick={() => {
              goto("/client/profile");
            }}
          >
            Cancel
          </button>
          <button className="btn btn-success ms-3">Save Changes</button>
        </div>
      </div>
      <div className="profile-edit mt-5">
        <h3 clasName="mb-0" style={{ marginBottom: "0" }}>
          Company Profile
        </h3>
        <p className="mb-0">Update your company photo and details here</p>
        <hr className="mt-2" />
        <div className="row">
          <div className="col-lg-4">
            <p className="mb-0 public-profile">Public Profile</p>
            <p>This will be displayed on your profile</p>
          </div>
          <div className="col-lg-8">
            <Form onSubmit={onSubmit} className="row w-100">
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={form.first_name}
                    name="first_name"
                    onChange={onChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    type="email"
                    placeholder="www.dcubeai.com"
                    name=""
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Control type="text" placeholder="Address 1" name="" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Select
                    value={form.country}
                    name="country"
                    onChange={onChange}
                  >
                    <option value="">Select</option>
                    {country.map((data) => {
                      return (
                        <option value={data.country}>{data.country}</option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Select
                    value={form.city}
                    name="city"
                    onChange={onChange}
                  >
                    <option value="">Select</option>
                    {city.map((data) => {
                      return <option value={data}>{data}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    name=""
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Control
                    type="email"
                    placeholder="www.dcubeai.com"
                    name=""
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Control type="text" placeholder="Address 2" name="" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Select
                    value={form.state}
                    name="state"
                    onChange={onChange}
                  >
                    <option value="">Select</option>
                    {state.map((data) => {
                      return <option value={data.name}>{data.name}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={form.zipcode}
                    name="zipcode"
                    onChange={onChange}
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </div>
      </div>

      {/* <div className="bg-white bg-space-radius">
        <div className="profile-heading">Edit Profile</div>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={form.first_name}
              name="first_name"
              onChange={onChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={form.last_name}
              name="last_name"
              onChange={onChange}
            />
          </Form.Group>

          <div className="grid-1fr ">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email Id</Form.Label>
              <Form.Control
                type="email"
                placeholder=""
                value={form.email}
                name="email"
                onChange={onChange}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={form.phone}
                name="phone"
                onChange={onChange}
              />
            </Form.Group>
          </div>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Address 1</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={form.address_1}
              name="address_1"
              onChange={onChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Address 2</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={form.address_2}
              name="address_2"
              onChange={onChange}
            />
          </Form.Group>
          <div className="grid-1fr ">
            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Select
                value={form.country}
                name="country"
                onChange={onChange}
              >
                <option value="">Select</option>
                {country.map((data) => {
                  return <option value={data.country}>{data.country}</option>;
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Select value={form.state} name="state" onChange={onChange}>
                <option value="">Select</option>
                {state.map((data) => {
                  return <option value={data.name}>{data.name}</option>;
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Select value={form.city} name="city" onChange={onChange}>
                <option value="">Select</option>
                {city.map((data) => {
                  return <option value={data}>{data}</option>;
                })}
             
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={form.zipcode}
                name="zipcode"
                onChange={onChange}
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="secondary"
              onClick={() => {
                goto("/client/profile");
              }}
              label="Cancel"
              buttonMode="button"
            />
            <Button
              type="primary"
              label="Save"
              buttonMode="submit"
              disabled={disabled}
            />
          </div>
        </Form>
      </div> */}
    </div>
  );
};

export default EditProfileForm;
