import React, { useState, useEffect } from "react";
import Select from "react-select";
import { css } from "@emotion/react";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import WanStatusGauge from "../../../Components/WanStatusGauge";
import TopTenChart from "../../../Components/TopTenChart";
import {
  dashboardWidgetEdit,
  dashboardWidgetGet,
  getDashboard,
} from "../../../Service";
import ThirtyDayGraph from "../../../Components/ThirtyDayGraph";
import Battery from "../../../Components/Battery";
import NetworkSpeed from "../../../Components/NetworkSpeed";
import { Form } from "react-bootstrap";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import CloseIcon from "../../../Assets/Images/close.svg";

const ReactGridLayout = WidthProvider(RGL);

// const widgetStyles = css({
//   padding: "16px",
//   backgroundColor: "#fff",
//   borderRadius: "8px",
//   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//   transition: "transform 150ms ease",
// });

const DashboardDrag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [widgets, setWidgets] = useState([
    { id: "wanStatus", layout: { x: 0, y: 0, w: 1, h: 2 } },
    { id: "battery", layout: { x: 1, y: 0, w: 1, h: 2 } },
    { id: "topAlerts", layout: { x: 2, y: 0, w: 2, h: 2 } },
    { id: "downloadSpeed", layout: { x: 0, y: 2, w: 2, h: 2 } },
    { id: "uploadSpeed", layout: { x: 2, y: 2, w: 2, h: 2 } },
    { id: "thirtyDayGraph", layout: { x: 2, y: 4, w: 4, h: 2 } },
  ]);

  const widgetOptions = [
    // { value: "wanStatus", label: "WAN Status" },
    // { value: "battery", label: "Battery" },
    // { value: "topAlerts", label: "Top Alerts" },
    // { value: "downloadSpeed", label: "Download Speed" },
    // { value: "uploadSpeed", label: "Upload Speed" },
    // { value: "thirtyDayGraph", label: "30-Day Graph" },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const fetchDashboardLayout = async () => {
      try {
        const data = await dashboardWidgetGet();
        console.log(data.widgets, "API widgets");
        setWidgets(data.widgets);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDashboardLayout();
  }, []);

  const dummyData = {
    thirtyDays: [
      { date: "2024-11-08", percentage: 66.67 },
      { date: "2024-11-04", percentage: 60.0 },
      { date: "2024-11-01", percentage: 50.0 },
      { date: "2024-10-30", percentage: 66.67 },
      { date: "2024-10-29", percentage: 100.0 },
    ],
    topNodes: [
      {
        name: "HP Printer 1",
        rank: 1,
        value: 0,
      },
      {
        name: "Sophos",
        rank: 2,
        value: 0,
      },
      {
        name: "Camera Office 2",
        rank: 3,
        value: 0,
      },
      {
        name: "Camera Office 1",
        rank: 4,
        value: 0,
      },
      {
        name: "Data Cube Website",
        rank: 5,
        value: 0,
      },
    ],
    speedData: {
      "Node A": [
        { interval_start: "2024-10-01", avg_speed: 3.0 },
        { interval_start: "2024-10-05", avg_speed: 4.0 },
      ],
    },
  };

  useEffect(() => {
    dispatch(setActivePage("dashboard"));
  }, [dispatch]);

  const handleChange = (selected) => {
    setSelectedOptions(selected || []);
  };

  const addWidgets = () => {
    if (selectedOptions.length === 0) {
      toast.error("Please select at least one widget to add.", {
        position: "top-right",
      });
      return;
    }

    const newWidgets = selectedOptions.map((option) => ({
      id: option.value,
      layout: { x: 0, y: Infinity, w: 1, h: 2 },
    }));

    setWidgets((prev) => [...prev, ...newWidgets]);
    setSelectedOptions([]);
  };

  const renderWidgetComponent = (id) => {
    switch (id) {
      case "wanStatus":
        return (
          <div
            className="bg-white bg-space-radius dashboard-card-wraper h-100"
            style={{
              height: "390px",
              width: "100%",
              cursor: "grab", // Indicates draggable area
            }}
          >
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="graph-heading">SLA LAST 24 HOURS</div>
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent drag on close button click
                  setWidgets(widgets.filter((data) => data.id !== "wanStatus"));
                }}
                style={{ background: "transparent", border: "0" }}
              >
                <img src={CloseIcon} style={{ width: "25px" }} alt="Close" />
              </button>
            </div>
            <WanStatusGauge value={70} />
          </div>
        );
      case "battery":
        return (
          <div
            className="bg-white bg-space-radius dashboard-card-wraper h-100"
            style={{
              height: "390px",
              width: "100%",
              cursor: "grab",
            }}
          >
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="graph-heading">UPS Battery Percentage</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setWidgets(widgets.filter((data) => data.id !== "battery"));
                }}
                style={{ background: "transparent", border: "0" }}
              >
                <img src={CloseIcon} style={{ width: "25px" }} alt="Close" />
              </button>
            </div>
            <Battery percentage={60} />
          </div>
        );
      case "topAlerts":
        return (
          <div
            className="bg-white bg-space-radius dashboard-card-wraper h-100 table-style-dashboard"
            style={{
              height: "390px",
              width: "100%",
              cursor: "grab",
            }}
          >
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="graph-heading">Top Alerting Host</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setWidgets(widgets.filter((data) => data.id !== "topAlerts"));
                }}
                style={{ background: "transparent", border: "0" }}
              >
                <img src={CloseIcon} style={{ width: "25px" }} alt="Close" />
              </button>
            </div>
            <TopTenChart data={dummyData.topNodes} />
          </div>
        );
      case "downloadSpeed":
        return (
          <div
            className="bg-white bg-space-radius dashboard-card-wraper h-100"
            style={{
              height: "390px",
              width: "100%",
              cursor: "grab",
            }}
          >
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="graph-heading">Download Speed</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setWidgets(
                    widgets.filter((data) => data.id !== "downloadSpeed")
                  );
                }}
                style={{ background: "transparent", border: "0" }}
              >
                <img src={CloseIcon} style={{ width: "25px" }} alt="Close" />
              </button>
            </div>
            <NetworkSpeed chartData={dummyData.speedData} />
          </div>
        );
      case "uploadSpeed":
        return (
          <div
            className="bg-white bg-space-radius dashboard-card-wraper h-100"
            style={{
              height: "390px",
              width: "100%",
              cursor: "grab",
            }}
          >
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="graph-heading">Upload Speed</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setWidgets(
                    widgets.filter((data) => data.id !== "uploadSpeed")
                  );
                }}
                style={{ background: "transparent", border: "0" }}
              >
                <img src={CloseIcon} style={{ width: "25px" }} alt="Close" />
              </button>
            </div>
            <NetworkSpeed chartData={dummyData.speedData} />
          </div>
        );
      case "thirtyDayGraph":
        return (
          <div
            className="bg-white bg-space-radius dashboard-card-wraper h-100"
            style={{
              height: "390px",
              width: "100%",
              cursor: "grab",
            }}
          >
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="graph-heading">SLA - Past 30 Days</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setWidgets(
                    widgets.filter((data) => data.id !== "thirtyDayGraph")
                  );
                }}
                style={{ background: "transparent", border: "0" }}
              >
                <img src={CloseIcon} style={{ width: "25px" }} alt="Close" />
              </button>
            </div>
            <ThirtyDayGraph data={dummyData.thirtyDays} />
          </div>
        );
      default:
        return null;
    }
  };

  const handleLayoutChange = (newLayout) => {
    const updatedWidgets = widgets.map((widget) => {
      const updatedLayout = newLayout.find((layout) => layout.i === widget.id);
      return { ...widget, layout: updatedLayout };
    });
    setWidgets(updatedWidgets);
  };

  const removeWidget = (id) => {
    setWidgets((prev) => prev.filter((widget) => widget.id !== id));
  };

  const layout = widgets.map((widget) => ({
    ...widget.layout,
    i: widget.id,
  }));

  return (
    <>
      <Form>
        <div
          className="d-flex align-items-end justify-content-end"
          style={{ gap: "15px" }}
        >
          <Form.Group>
            <Select
              isMulti
              placeholder="Select Widget"
              value={selectedOptions}
              onChange={handleChange}
              options={widgetOptions.filter(
                (option) =>
                  !widgets.some((widget) => widget.id === option.value)
              )}
              isSearchable
            />
          </Form.Group>
          <Button
            type="primary"
            label="Add Widget"
            onClick={(e) => {
              e.preventDefault();
              addWidgets();
            }}
          />
        </div>
      </Form>

      <ReactGridLayout
        className="layout"
        layout={layout}
        cols={4}
        rowHeight={250}
        width={1200}
        onLayoutChange={handleLayoutChange}
      >
        {widgets.map((widget) => (
          <div key={widget.id}>
            {renderWidgetComponent(widget.id)}{" "}
            {/* Render the widget as a styled component */}
          </div>
        ))}
      </ReactGridLayout>

      <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
        <Button
          onClick={() => navigate("/client")}
          label="Cancel"
          buttonMode="button"
        />
        <Button
          type="primary"
          label="Save"
          buttonMode="button"
          onClick={async () => {
            try {
              const payload = widgets.map((widget) => ({
                id: widget.id, // Include the widget id for identification
                layout: {
                  x: widget.layout.x,
                  y: widget.layout.y,
                  w: widget.layout.w,
                  h: widget.layout.h,
                },
              }));
              await dashboardWidgetEdit(payload);
              navigate("/client");
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </div>
    </>
  );
};

export default DashboardDrag;
