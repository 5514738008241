import React, { useEffect, useState } from "react";
import "./style.css";
import { io } from "socket.io-client";
import { setActivePage } from "../../../Redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  adminNotificationList,
  adminNotificationReadAll,
} from "../../../Service";
import { setUnreadCount } from "../../../Redux/notificationSlice";
import deleteIcon from "../../../Assets/Images/delete.svg";
import DummyImg from "../../../Assets/Images/dummy-img.png";

const Notifications = () => {
  const [tickets, setTickets] = useState([]);
  const [newTicket, setNewTicket] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  // const [unreadCount, setUnreadCount] = useState(0);

  const dispatch = useDispatch();

  const unreadCount = useSelector((state) => state.notification.unreadCount);

  useEffect(() => {
    console.log(unreadCount, "Notification Count");
  }, [unreadCount]);

  useEffect(() => {
    const fetchNotificationList = async () => {
      try {
        const data = await adminNotificationList();
        setTickets(data);
        const unread = data.filter((ticket) => !ticket.is_read).length; // Calculate unread tickets
        dispatch(setUnreadCount(unread)); // Update unread count
      } catch (error) {
        console.log(error);
      }
    };

    dispatch(setActivePage("notification"));

    fetchNotificationList();

    return async () => {
      try {
        await adminNotificationReadAll();
        await fetchNotificationList();
      } catch {}
    };
  }, [dispatch, newTicket]);

  useEffect(() => {
    // Connect to the Socket.IO server
    const socket = io(process.env.REACT_APP_API_SOCKET_URL); // Adjust the URL to match your backend

    // Listen for the 'connect' event
    socket.on("connect", () => {
      console.log("Connected to Socket.IO server");
      console.log("Socket ID:", socket.id);
      setSocketConnected(true);

      // Optionally, join a specific room
      socket.emit("join", { room: "admin_room" });
    });

    // Handle connection errors
    socket.on("connect_error", (error) => {
      console.error("Connection failed:", error);
    });

    // Listen for the 'new_ticket' event
    socket.on("new_ticket", (data) => {
      console.log("New ticket received:", data);
      setNewTicket(!newTicket);
    });

    // Handle disconnections
    socket.on("disconnect", () => {
      console.log("Disconnected from the server");
      setSocketConnected(false);
    });

    // Cleanup the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
       <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
        <h4 className="sub-title">Notifications</h4>
        {/* <div className="notification-delete">
        <img src={deleteIcon} alt="" className="" style={{width: '15px'}} />
        </div> */}
      </div>
      <div className="bg-white bg-space-radius">
      {/* <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
        <h4 className="sub-title">Notifications</h4> */}
        {/* <div className="notification-delete">
        <img src={deleteIcon} alt="" className="" style={{width: '15px'}} />
        </div> */}
      {/* </div> */}
           {/* {socketConnected ? <p>Connected to server</p> : <p>Disconnected</p>} */}
      <div id="ticket-list">
        {tickets.map((ticket, index) => (
          <div
            key={index}
            className={`notification-container ${
              ticket.is_read ? "read" : "unread"
            }`}
          >
            <div className="d-flex align-items-center">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
              </div>
              {/* <div className="me-2">
              <img src={DummyImg} alt="" className="" style={{width: '45px'}} />
              </div> */}
              <div>
              <p className="mb-0">
             <b style={{color: '#082064', fontWeight: '500'}}>  {ticket.data.created_by} </b> raised a  <b style={{color: '#082064', fontWeight: '500'}}> new {ticket.data.type} </b>
             </p>
             <p className="mb-0" style={{color: '#7C7C7C', fontSize: '16px'}}>
             {ticket.data.timestamp}
             </p>
            </div>
            </div>
            <div className="notification-delete">
            <img src={deleteIcon} alt="" className="" style={{width: '15px'}} />
            </div>
          </div>
         
        ))}
      </div>
    </div>
      </div>
     
  );
};

export default Notifications;
